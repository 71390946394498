<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div class="landing">
            <v-breadcrumbs :items="items">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                    :to="item.href"
                    :disabled="item.disabled"
                >
                  {{ item.text.toUpperCase() }}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          <h1>{{ objPage.name }}</h1>
          <div
            class="landing__wrapper text-justify"
            v-html="objPage.content"
          ></div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as names from "../store/names";
import { mapActions, mapGetters } from "vuex";

export default {
  metaInfo: {
    title: "Сотрудничество",
    titleTemplate: "%s ← Medveddoc",
    meta: [
      {
        name: "description",
        content:
          "Подбор врача по цене, клинике. График работы врачей. Подбор медицинских услуг по РФ",
      },
      { charset: "utf-8" },
      { property: "og:title", content: "Сотрудничество" },
      { property: "og:site_name", content: "medveddoc.com" },
      { property: "og:type", content: "website" },
      { property: "og:url", content: "https://medveddoc.com" },
      {
        property: "og:description",
        content:
          "Подбор врача по цене, клинике. График работы врачей. Подбор медицинских услуг по РФ",
      },
    ],
  },
  name: "TheCooperationPage",
  created() {
    this.getPage({ slug: "clinic-landing" });
  },
  data: () => ({
      items: [
        {
          text: 'главная',
          disabled: false,
          href: '/',
        },
        {
          text: 'Сотрудничество',
          disabled: true,
          href: '/clinic-landing',
        }],
    }),
  computed: {
    ...mapGetters({
      objPage: names.OBJ_PAGE,
    }),
  },
  methods: {
    ...mapActions({
      getPage: names.ACTION_GET_PAGE,
    }),
  },
};
</script>

<style lang="scss" scoped>
 .v-breadcrumbs {
   padding: 18px 0px ;
}

</style>
